var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticStyle:{"padding-top":"10px"}},[_c('a-form',{staticClass:"p0-32-10-32 form-content",attrs:{"label-col":_vm.labelCol,"wrapper-col":_vm.wrapperCol,"form":_vm.form}},[_c('a-form-item',{attrs:{"label":"名称"}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
          'name',
          { rules: [{ required: true, message: '配额名称不能为空!' }] },
        ]),expression:"[\n          'name',\n          { rules: [{ required: true, message: '配额名称不能为空!' }] },\n        ]"}],attrs:{"placeholder":"请输入名称"}})],1),_c('a-form-item',{attrs:{"label":"时长（月）"}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
          'month',
          { rules: [{ required: true, message: '配额时长不能为空!' }, { validator: _vm.checkNumber }] },
        ]),expression:"[\n          'month',\n          { rules: [{ required: true, message: '配额时长不能为空!' }, { validator: checkNumber }] },\n        ]"}],attrs:{"placeholder":"请输入时长"}})],1),_c('a-form-item',{attrs:{"label":"主机配额（个）"}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
          'max_hosts',
          { rules: [{ required: true,  message: '主机配额不能为空!' },  { validator: _vm.checkNumber },] },
        ]),expression:"[\n          'max_hosts',\n          { rules: [{ required: true,  message: '主机配额不能为空!' },  { validator: checkNumber },] },\n        ]"}],attrs:{"placeholder":"请输入主机配额"}})],1),_c('a-form-item',{attrs:{"label":"任务配额（个）"}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
          'max_tasks',
          { rules: [{ required: true, message: '任务配额不能为空!' }, { validator: _vm.checkNumber }] },
        ]),expression:"[\n          'max_tasks',\n          { rules: [{ required: true, message: '任务配额不能为空!' }, { validator: checkNumber }] },\n        ]"}],attrs:{"placeholder":"请输入任务配额"}})],1),_c('a-form-item',{attrs:{"label":"调度配额（个）"}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
          'max_schedules',
          { rules: [{ required: true, message: '调度配额不能为空!' }, { validator: _vm.checkNumber }] },
        ]),expression:"[\n          'max_schedules',\n          { rules: [{ required: true, message: '调度配额不能为空!' }, { validator: checkNumber }] },\n        ]"}],attrs:{"placeholder":"请输入调度配额"}})],1),_c('a-form-item',{attrs:{"label":"描述"}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
          'description',
          { rules: [{ required: false, message: '配额描述不能为空!' }] },
        ]),expression:"[\n          'description',\n          { rules: [{ required: false, message: '配额描述不能为空!' }] },\n        ]"}],attrs:{"placeholder":"请输入描述"}})],1)],1),_c('div',{staticClass:"ant-modal-confirm-btns-new"},[_c('a-button',{staticStyle:{"margin-right":"10px"},attrs:{"type":"primary","loading":_vm.loading},on:{"click":function($event){$event.stopPropagation();return _vm.handleSubmit.apply(null, arguments)}}},[_vm._v("确认")]),_c('a-button',{on:{"click":function($event){$event.stopPropagation();return _vm.formCancel.apply(null, arguments)}}},[_vm._v("取消")])],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }