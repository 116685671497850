<!--
 * @Author: mjzhu
 * @Date: 2022-06-08 11:38:30
 * @LastEditTime: 2022-09-07 23:28:31
 * @FilePath: \awx-ui\src\pages\management\commponents\addment.vue
-->
<template>
  <div style="padding-top: 10px">
    <a-form
      :label-col="labelCol"
      :wrapper-col="wrapperCol"
      :form="form"
      class="p0-32-10-32 form-content"
    >
      <a-form-item label="名称">
        <a-input
          v-decorator="[
            'name',
            { rules: [{ required: true, message: '配额名称不能为空!' }] },
          ]"
          placeholder="请输入名称"
        />
      </a-form-item>

      <a-form-item label="时长（月）">
        <a-input
          v-decorator="[
            'month',
            { rules: [{ required: true, message: '配额时长不能为空!' }, { validator: checkNumber }] },
          ]"
          placeholder="请输入时长"
        />
      </a-form-item>
      <a-form-item label="主机配额（个）">
        <a-input
          v-decorator="[
            'max_hosts',
            { rules: [{ required: true,  message: '主机配额不能为空!' },  { validator: checkNumber },] },
          ]"
          placeholder="请输入主机配额"
        />
      </a-form-item>

      <a-form-item label="任务配额（个）">
        <a-input
          v-decorator="[
            'max_tasks',
            { rules: [{ required: true, message: '任务配额不能为空!' }, { validator: checkNumber }] },
          ]"
          placeholder="请输入任务配额"
        />
      </a-form-item>
      <a-form-item label="调度配额（个）">
        <a-input
          v-decorator="[
            'max_schedules',
            { rules: [{ required: true, message: '调度配额不能为空!' }, { validator: checkNumber }] },
          ]"
          placeholder="请输入调度配额"
        />
      </a-form-item>
      <a-form-item label="描述">
        <a-input
          v-decorator="[
            'description',
            { rules: [{ required: false, message: '配额描述不能为空!' }] },
          ]"
          placeholder="请输入描述"
        />
      </a-form-item>
    </a-form>
    <div class="ant-modal-confirm-btns-new">
      <a-button
        style="margin-right: 10px"
        type="primary"
        @click.stop="handleSubmit"
        :loading="loading"
        >确认</a-button
      >
      <a-button @click.stop="formCancel">取消</a-button>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    detail: {
      type: Object,
      default: function () {
        return {};
      },
    },
    callBack: Function,
  },
  data() {
    return {
      editFlag: false,
      labelCol: {
        xs: { span: 24 },
        sm: { span: 6 },
      },
      wrapperCol: {
        xs: { span: 24 },
        sm: { span: 18 },
      },
      form: this.$form.createForm(this),
      loading: false,
    };
  },
  watch: {},
  methods: {
    checkName(rule, value, callback) {
      var reg = /[\u4E00-\u9FA5]|[\uFE30-\uFFA0]/g;
      if (reg.test(value)) {
        callback(new Error("名称中不能包含中文"));
      }
      if (/\s/g.test(value)) {
        callback(new Error("名称中不能包含空格"));
      }
      callback();
    },
    checkNumber(rule, value, callback) {
      var reg = /^[1-9]+[0-9]*$/;
      if (!reg.test(value) && value) {
        callback(new Error("请输入数字"));
      }
      callback();
    },
    formCancel() {
      this.$destroyAll();
    },
    handleSubmit(e) {
      console.log(1);
      const _this = this;
      e.preventDefault();
      this.form.validateFields((err, values) => {
        if (err) return false
        const params = {
          max_hosts: values.max_hosts * 1,

          max_schedules: max_schedules._value * 1,
          max_tasks: max_tasks._value * 1,
          month: month._value * 1,
          description: description._value,
          name: values.name,
        };
        console.log(params, "params");
        this.loading = true;
        // console.log(typeof values);
        this.$axiosJsonPost(global.API.getManagementList, params)
          .then((res) => {
            this.loading = false;
            if ([200, 201, 204].includes(res.status)) {
              this.$message.success("保存成功", 2);
              this.$destroyAll();
              _this.callBack();
            }
          })
          .catch((err) => {});
      });
    },
    echoUSer() {
      if (JSON.stringify(this.detail) !== "{}") {
        this.editFlag = true;
        this.form.getFieldsValue(["name", "description"]);
        this.form.setFieldsValue({
          name: this.detail.name,
          description: this.detail.description,
          password: this.detail.password,
          email: this.detail.email,
        });
      } else {
        this.form.getFieldsValue(["username", "description"]);
        this.form.setFieldsValue({
          name: "",
          description: "",
        });
      }
    },
  },
  mounted() {
    this.echoUSer();
  },
};
</script>
<style lang="less" scoped></style>
